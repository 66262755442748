<template>
  <div class="step">
    <div
      v-for="(item, index) in steps"
      :key="item.pathName"
      class="step-item"
      :class="{active: index <= stepIndex}"
    >
      <span v-if="index >= stepIndex">{{ index+1 }}</span>
      <span v-else><i class="el-icon-check" /></span>
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        { name: '宝宝信息', pathName: 'eval.editChildrenInfo' },
        { name: '测评问答', pathName: 'eval.question' },
        { name: '测评报告', pathName: 'eval.report' },
      ]
    };
  },
  computed: {
    stepIndex() {
      const pathName = this.$route.name;
      const stepIndex = this.steps.reduce((pre, cur, curIndex) => (pathName === cur.pathName ? curIndex : pre), 0);
      return stepIndex;
    }
  }
};
</script>

<style lang="less" scoped>
.step{
  white-space: nowrap;
  &-item{
    display: inline-block;
    position: relative;
    &:not(:first-child){
      margin-left: 56px;
      &::before{
        border-bottom: 1px dashed #CCC;
      }
    }
    &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: -51px;
      height: 1px;
      width: 44px;
    }
    span:nth-child(1){
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      line-height: 30px;
      margin-right: 9px;
      text-align: center;
      color: white;
      font-size: 16px;
      background: #DADADA;
    }
    span:nth-child(2){
      font-size: 16px;
    }
    &.active{
      span:nth-child(1){
        background: linear-gradient(270deg, #47C2C2 2.64%, #78DBD9 100%);
      }
      &::before{
        border-bottom-color: #5FB4BF;
      }
    }
  }
}
</style>
